import * as tslib_1 from "tslib";
import { Observable } from 'rxjs';
import { tap, pluck, map, shareReplay } from 'rxjs/operators';
import { Stores, LegacyApiResponse } from '../models';
import { selectAll } from '../stores/selectors/cart.selectors';
import { load, add, remove } from '../stores/actions/cart.actions';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./user.service";
import * as i3 from "@ngrx/store";
var CartService = /** @class */ (function () {
    function CartService(_http, _user, _store) {
        var _this = this;
        this._http = _http;
        this._user = _user;
        this._store = _store;
        // public readonly action$ = new ReplaySubject<Action>();
        // public readonly cart$: Observable<Cart> = of([]).pipe(
        // 	switchMapTo(this.action$),
        // 	tap(action => console.log('cart action', action)),
        // 	concatMap(action => {
        // 		switch (action.type) {
        // 			case 'load': return this.getCart();
        // 			case 'add': return this.addCartItem(action.design_id);
        // 			case 'remove': return this.removeCartItem(action.design_id, action.amount || 1);
        // 		}
        // 	}),
        // 	shareReplay()
        // );
        this.cart$ = this._store.select(selectAll).pipe(tap(function (x) { return console.log('cart'); }), shareReplay());
        this.cartItemCount$ = this.cart$.pipe(map(function (cart) { return cart.reduce(function (count, item) { return count += item.amount; }, 0); }), tap(function (count) { return console.log('cart item count', count); }));
        this.cartPriceTotal$ = this.cart$.pipe(map(function (cart) { return cart.reduce(function (price, item) { return price += (item.amount * item.design.product_price); }, 0); }), tap(function (total) { return console.log('cart price total', total); }));
        this._user.user$.subscribe(function (user) {
            console.log('cart service subscription to user', user);
            if (user) {
                _this._store.dispatch(load());
            }
        });
    }
    CartService.prototype.addOne = function (design_id) {
        this._store.dispatch(add({ design_id: design_id }));
    };
    CartService.prototype.removeOne = function (design_id) {
        this.removeMany(design_id);
    };
    CartService.prototype.removeMany = function (design_id, amount) {
        if (amount === void 0) { amount = 1; }
        this._store.dispatch(remove({ design_id: design_id, amount: amount }));
    };
    CartService.prototype.orderCart = function (data) {
        var _this = this;
        return this._http.post('/legacy-api/api-js/json/orderCart', tslib_1.__assign({}, data)).pipe(pluck('result'), tap(function (result) {
            console.log('orderCart tap', result);
            _this._store.dispatch(load());
        }));
    };
    CartService.prototype.getStores = function () {
        return this._http.get('/legacy-api/api-js/json/getStores').pipe(pluck('result'), tap(function (stores) {
            console.log('getStores tap', stores);
        }));
    };
    CartService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CartService_Factory() { return new CartService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.UserService), i0.ɵɵinject(i3.Store)); }, token: CartService, providedIn: "root" });
    return CartService;
}());
export { CartService };
