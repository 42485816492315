import * as tslib_1 from "tslib";
import { NavigationEnd } from '@angular/router';
import { LoginRegistrationModalComponent, CartModalComponent } from '@app/shared/modals';
import { filter, first, switchMap } from 'rxjs/operators';
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(router, toastService, _modal, _user, _cart) {
        var _this = this;
        this.toastService = toastService;
        this._modal = _modal;
        this._user = _user;
        this._cart = _cart;
        this.isNavbarCollapsed = true;
        this.user$ = this._user.user$;
        this.cartItemCount$ = this._cart.cartItemCount$;
        router.events.subscribe(function (event) {
            if (event instanceof NavigationEnd) {
                _this.isNavbarCollapsed = true;
            }
        });
        // this.toastService.show('hiba', 'I am a standard toast', { delay: '500000' });
        _user.passwordResetToken$.pipe(filter(function (passwordResetToken) { return !!passwordResetToken; }), first(), switchMap(function (token) { return _user.getUserByPasswordResetToken(token); }, function (token, user) { return [token, user]; })).subscribe(function (_a) {
            var _b = tslib_1.__read(_a, 2), token = _b[0], user = _b[1];
            console.log(token, user);
            var modal = _this._modal.open(LoginRegistrationModalComponent, { size: 'lg' });
            modal.componentInstance.activeForm = 'password-reset';
            modal.componentInstance.passwordResetForm.patchValue({ token: token });
        }, function (error) {
            console.error(error.error);
        });
    }
    HeaderComponent.prototype.showLoginRegisterModal = function () {
        this._modal.open(LoginRegistrationModalComponent, { size: 'lg' });
    };
    HeaderComponent.prototype.showCartModal = function () {
        this._modal.open(CartModalComponent, { size: 'lg' });
    };
    HeaderComponent.prototype.logout = function () {
        var isGuest = this._user.user$.getValue().auth_type === 'guest';
        if (isGuest && !confirm('Vendégként használod az oldalt. Ha kilépsz, a tervezett címkéid és a kosarad tartalma elvész!'))
            return;
        this._user.logout();
    };
    return HeaderComponent;
}());
export { HeaderComponent };
