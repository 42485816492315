import { ErrorHandler } from '@angular/core';
import { SentryErrorHandler } from './core/services/sentry-error-handler';
import * as SentryBrowser from '@sentry/browser';
import { environment } from '@app-env/environment';
var ɵ0 = SentryBrowser;
var SENTRY_PROVIDERS = environment.production ? [
    { provide: 'Sentry', useValue: ɵ0 },
    { provide: ErrorHandler, useClass: SentryErrorHandler }
] : [];
var AppBrowserModule = /** @class */ (function () {
    function AppBrowserModule() {
    }
    return AppBrowserModule;
}());
export { AppBrowserModule };
export { ɵ0 };
