import { createReducer, on } from '@ngrx/store';
import { initialState, adapter } from '../states/cart.state';
import { loadSuccess, updateSuccess, addSuccess } from '../actions/cart.actions';
var ɵ0 = function (state, _a) {
    var cart = _a.cart;
    return adapter.addAll(cart, state);
}, ɵ1 = function (state, _a) {
    var cartItemUpdate = _a.cartItemUpdate;
    if (cartItemUpdate.changes.amount > 0) {
        return adapter.updateOne(cartItemUpdate, state);
    }
    else {
        return adapter.removeOne(cartItemUpdate.id.toString(), state);
    }
}, ɵ2 = function (state, _a) {
    var cartItem = _a.cartItem;
    return adapter.upsertOne(cartItem, state);
};
// reducers
var cartReducer = createReducer(initialState, on(loadSuccess, ɵ0), on(updateSuccess, ɵ1), on(addSuccess, ɵ2));
export function reducer(state, action) {
    return cartReducer(state, action);
}
export { ɵ0, ɵ1, ɵ2 };
