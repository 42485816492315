import * as tslib_1 from "tslib";
import * as i0 from "@angular/core";
var ToastService = /** @class */ (function () {
    function ToastService() {
        this.toasts = [];
    }
    // show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    // 	console.log('toast');
    // 	this.toasts.push({ textOrTpl, ...options });
    // }
    ToastService.prototype.show = function (header, body, options) {
        if (options === void 0) { options = {}; }
        this.toasts.push(tslib_1.__assign({ header: header, body: body }, options));
    };
    ToastService.prototype.remove = function (toast) {
        this.toasts = this.toasts.filter(function (t) { return t !== toast; });
    };
    ToastService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ToastService_Factory() { return new ToastService(); }, token: ToastService, providedIn: "root" });
    return ToastService;
}());
export { ToastService };
