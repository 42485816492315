import * as tslib_1 from "tslib";
import { BehaviorSubject } from 'rxjs';
import { tap, pluck } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UserService = /** @class */ (function () {
    function UserService(_http) {
        this._http = _http;
        this.user$ = new BehaviorSubject(null);
        this.passwordResetToken$ = new BehaviorSubject(null);
        this.getLoggedInUser().subscribe();
    }
    UserService.prototype.getLoggedInUser = function () {
        var _this = this;
        return this._http.get('/legacy-api/api-js/json/getLoggedInUser').pipe(pluck('result', 'user'), tap(function (user) {
            console.log('getLoggedInUser tap', user);
            if (user)
                _this.user$.next(user);
        }));
    };
    UserService.prototype.login = function (data) {
        var _this = this;
        return this._http.post('/legacy-api/api-js/json/login', tslib_1.__assign({ auth_type: 'password' }, data)).pipe(pluck('result', 'user'), tap(function (user) {
            console.log('login tap', user);
            if (user)
                _this.user$.next(user);
        }));
    };
    UserService.prototype.logout = function () {
        var _this = this;
        this._http.post('/legacy-api/api-js/json/logout', {}).subscribe(function () {
            _this.user$.next(null);
        });
    };
    UserService.prototype.register = function (data) {
        return this._http.post('/legacy-api/api-js/json/register', tslib_1.__assign({ auth_type: 'password' }, data)).pipe(pluck('result', 'user'), tap(function (user) {
            console.log('register tap', user);
        }));
    };
    UserService.prototype.createGuestUser = function () {
        var _this = this;
        return this._http.post('/legacy-api/api-js/json/register', {
            auth_type: 'guest'
        }).pipe(pluck('result', 'user'), tap(function (user) {
            console.log('createGuestUser tap', user);
            if (user)
                _this.user$.next(user);
        }));
    };
    UserService.prototype.resetPassword = function (data) {
        return this._http.post('/legacy-api/api-js/json/resetPassword', data).pipe(pluck('result', 'user'), tap(function (user) {
            console.log('register tap', user);
        }));
    };
    UserService.prototype.sendPasswordResetToken = function (data) {
        return this._http.post('/legacy-api/api-js/json/sendPasswordResetToken', data).pipe(pluck('result', 'user'), tap(function (user) {
            console.log('register tap', user);
        }));
    };
    UserService.prototype.getUserByPasswordResetToken = function (token) {
        return this._http.post('/legacy-api/api-js/json/getUserByPasswordResetToken', {
            token: token,
        }).pipe(pluck('result', 'user'), tap(function (user) {
            console.log('register tap', user);
        }));
    };
    UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpClient)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
