import * as tslib_1 from "tslib";
import { StoreModule } from '@ngrx/store';
import { EffectsModule, Effect, ROOT_EFFECTS_INIT, ofType } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@app-env/environment';
import { makeStateKey } from '@angular/platform-browser';
import { tap } from 'rxjs/operators';
export function logger(reducer) {
    return function newReducer(state, action) {
        console.groupCollapsed(action.type);
        var prevState = tslib_1.__assign({}, state);
        var nextState = reducer(state, action);
        console.log("%c prev state", "color: #9E9E9E; font-weight: bold", prevState);
        console.log("%c action", "color: #03A9F4; font-weight: bold", action);
        console.log("%c next state", "color: #4CAF50; font-weight: bold", nextState);
        console.groupEnd();
        return nextState;
    };
}
export function stateSetter(reducer) {
    return function (state, action) {
        if (action.type === 'SET_ROOT_STATE') {
            return action.payload;
        }
        return reducer(state, action);
    };
}
export var metaReducers = [stateSetter];
if (!environment.production)
    metaReducers.push(logger);
var RootInitEffects = /** @class */ (function () {
    function RootInitEffects(_actions$, _store, _transferState) {
        var _this = this;
        this._actions$ = _actions$;
        this._store = _store;
        this._transferState = _transferState;
        this.init$ = this._actions$.pipe(ofType(ROOT_EFFECTS_INIT), tap(function (_) {
            // console.log('RootInitEffects: init$');
            // NGRX transfer state
            var NGRX_STATE = makeStateKey('NGRX_STATE');
            if (_this._transferState.hasKey(NGRX_STATE)) {
                // we are in the browser and transferstate has a key for NGRX state.
                var state = _this._transferState.get(NGRX_STATE, null);
                _this._transferState.remove(NGRX_STATE);
                _this._store.dispatch({ type: 'SET_ROOT_STATE', payload: state });
                console.log('Got state from server', state);
            }
            else {
                // we are on the server, so we set up a transferstate callback to serialze ngrx state
                _this._transferState.onSerialize(NGRX_STATE, function () {
                    var state;
                    _this._store.subscribe(function (saveState) {
                        state = saveState;
                    }).unsubscribe();
                    console.log('Set for browser', state);
                    return state;
                });
            }
        }));
    }
    tslib_1.__decorate([
        Effect({ dispatch: false })
    ], RootInitEffects.prototype, "init$", void 0);
    return RootInitEffects;
}());
export { RootInitEffects };
export var NGRX_CONFIG = [
    StoreModule.forRoot({}, {
        metaReducers: metaReducers,
        runtimeChecks: {
            strictStateImmutability: true,
            strictActionImmutability: true,
            strictStateSerializability: true,
            strictActionSerializability: true,
        }
    }),
    EffectsModule.forRoot([RootInitEffects]),
];
if (!environment.production) {
    NGRX_CONFIG.push(StoreDevtoolsModule.instrument({
        maxAge: 25,
    }));
}
