import { Routes } from '@angular/router';
var ɵ0 = function () { return import("./home/home.module.ngfactory").then(function (m) { return m.HomeModuleNgFactory; }); };
var appRoutes = [
    {
        path: '',
        loadChildren: ɵ0
    },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0 };
