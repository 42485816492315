var _this = this;
import * as tslib_1 from "tslib";
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { hmrBootstrap } from './hmr';
import * as __NgCli_bootstrap_1 from "./app/app.browser.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
// import { create } from 'rxjs-spy';
// if (!environment.production) {
// 	const spy = create();
// 	spy.log();
// }
if (environment.production) {
    enableProdMode();
    [
        'debug', 'log', 'info', 'warn', 'error',
        'group', 'groupCollapsed', 'groupEnd',
        'dir', 'dirxml', 'trace', 'profile'
    ].forEach(function (method) { return window.console[method] = function () { return void 0; }; });
}
var bootstrap = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
    return tslib_1.__generator(this, function (_a) {
        return [2 /*return*/, __NgCli_bootstrap_2.platformBrowser()
                .bootstrapModuleFactory(__NgCli_bootstrap_1.AppBrowserModuleNgFactory)
                .catch(function (err) { return console.log(err); })];
    });
}); };
var ɵ0 = bootstrap;
if (environment.hmr) {
    if (module.hot) {
        hmrBootstrap(module, bootstrap);
    }
    else {
        console.error('HMR is not enabled!');
    }
}
else {
    document.addEventListener('DOMContentLoaded', function () {
        console.log('DOMContentLoaded');
        bootstrap();
    });
}
export { ɵ0 };
